import policeGif1 from "../../../../src/images/police-indianapolis.gif";
import policeGif2 from "../../../../src/images/police-terry.gif";
import policeGif3 from "../../../../src/images/police-cortez.gif";
import policeGif4 from "../../../../src/images/police-wardlow.gif";
import policeGif5 from "../../../../src/images/police-jl.gif";
import policeGif6 from "../../../../src/images/police-mimms.gif";
import * as React from 'react';
export default {
  policeGif1,
  policeGif2,
  policeGif3,
  policeGif4,
  policeGif5,
  policeGif6,
  React
};