import islandWebm from "../../../../src/video/lastgen-island.webm";
import islandMp4 from "../../../../src/video/lastgen-island.mp4";
import floodWebm from "../../../../src/video/lastgen-flood.webm";
import floodMp4 from "../../../../src/video/lastgen-flood.mp4";
import slrTreesWebm from "../../../../src/video/lastgen-slr-trees.webm";
import slrTreesMp4 from "../../../../src/video/lastgen-slr-trees.mp4";
import co2IntroWebm from "../../../../src/video/lastgen-co2-intro.webm";
import co2IntroMp4 from "../../../../src/video/lastgen-co2-intro.mp4";
import * as React from 'react';
export default {
  islandWebm,
  islandMp4,
  floodWebm,
  floodMp4,
  slrTreesWebm,
  slrTreesMp4,
  co2IntroWebm,
  co2IntroMp4,
  React
};