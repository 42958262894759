import introWebm from "../../../../src/video/unresolved-intro-anim.webm";
import introMp4 from "../../../../src/video/unresolved-intro-anim.mp4";
import appMp4 from "../../../../src/video/unresolved-app-ui.mp4";
import appWebm from "../../../../src/video/unresolved-app-ui.webm";
import titleMp4 from "../../../../src/video/unresolved-title.mp4";
import titleWebm from "../../../../src/video/unresolved-title.webm";
import * as React from 'react';
export default {
  introWebm,
  introMp4,
  appMp4,
  appWebm,
  titleMp4,
  titleWebm,
  React
};