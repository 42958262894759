import helgaWebm from "../../../../src/video/fetch-ruffcut-helga.webm";
import helgaMp4 from "../../../../src/video/fetch-ruffcut-helga.mp4";
import helgaPoster from "../../../../src/video/fetch-ruffcut-helga.jpg";
import blackmuzzleWebm from "../../../../src/video/fetch-ruffcut-blackmuzzle.webm";
import blackmuzzleMp4 from "../../../../src/video/fetch-ruffcut-blackmuzzle.mp4";
import blackmuzzlePoster from "../../../../src/video/fetch-ruffcut-blackmuzzle.jpg";
import arthurLungParticlesWebm from "../../../../src/video/arthur-lung-particles.webm";
import arthurLungParticlesMp4 from "../../../../src/video/arthur-lung-particles.mp4";
import arthurLungParticlesPoster from "../../../../src/video/arthur-lung-particles.png";
import greensAcornWebm from "../../../../src/video/greens-acorn.webm";
import greensAcornMp4 from "../../../../src/video/greens-acorn.mp4";
import greensAcornPoster from "../../../../src/video/greens-acorn.jpg";
import marthaKibbleWebm from "../../../../src/video/martha-stories-kibble.webm";
import marthaKibbleMp4 from "../../../../src/video/martha-stories-kibble.mp4";
import marthaKibblePoster from "../../../../src/video/martha-stories-kibble.jpg";
import marthaInventionsWebm from "../../../../src/video/martha-stories-inventions.webm";
import marthaInventionsMp4 from "../../../../src/video/martha-stories-inventions.mp4";
import marthaInventionsPoster from "../../../../src/video/martha-stories-inventions.jpg";
import * as React from 'react';
export default {
  helgaWebm,
  helgaMp4,
  helgaPoster,
  blackmuzzleWebm,
  blackmuzzleMp4,
  blackmuzzlePoster,
  arthurLungParticlesWebm,
  arthurLungParticlesMp4,
  arthurLungParticlesPoster,
  greensAcornWebm,
  greensAcornMp4,
  greensAcornPoster,
  marthaKibbleWebm,
  marthaKibbleMp4,
  marthaKibblePoster,
  marthaInventionsWebm,
  marthaInventionsMp4,
  marthaInventionsPoster,
  React
};