import hotspotWebm from "../../../../src/video/transparency-hotspot.webm";
import hotspotMp4 from "../../../../src/video/transparency-hotspot.mp4";
import quotesWebm from "../../../../src/video/transparency-quotes.webm";
import quotesMp4 from "../../../../src/video/transparency-quotes.mp4";
import * as React from 'react';
export default {
  hotspotWebm,
  hotspotMp4,
  quotesWebm,
  quotesMp4,
  React
};