import protoWebm from "../../../../src/video/virus-prototype.webm";
import protoMp4 from "../../../../src/video/virus-prototype.mp4";
import smsWebm from "../../../../src/video/virus-sms.webm";
import smsMp4 from "../../../../src/video/virus-sms.mp4";
import * as React from 'react';
export default {
  protoWebm,
  protoMp4,
  smsWebm,
  smsMp4,
  React
};